import React from "react"
import { Container, SimpleGrid, Box } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "../../components/ContactForm"

const Contact = () => (
  <section id="section-contact">
    <Container maxW="container.lg" pt={12} pb={10}>
      <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={5} pt={4}>
        <Box
          justifySelf="self-start"
          alignSelf="center"
          textAlign="left"
          w="100%"
          order={{ base: "2", md: "1" }}
        >
          <ContactForm />
        </Box>
        <Box
          justifySelf={{ base: "center", md: "self-end" }}
          order={{ base: "1", md: "2" }}
        >
          <StaticImage src="../../images/contact.png" alt="Contact" />
        </Box>
      </SimpleGrid>
    </Container>
  </section>
)

export default Contact
