import React from "react"
import {
  Box,
  Icon,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react"
import { GiHamburgerMenu } from "react-icons/gi"
import SocialLinks from "../../../SocialLinks"
import Menu from "../Menu"

const HamburgerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box display={{ md: "none" }} justifySelf="self-end">
      <Icon
        as={GiHamburgerMenu}
        alt="Menu"
        boxSize={7}
        cursor="pointer"
        onClick={onOpen}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <Menu onCloseDrawer={onClose} />
          </DrawerBody>
          <DrawerFooter>
            <Box w="100%" py={6} textAlign="center">
              <SocialLinks />
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default HamburgerMenu
