import React from "react"
import "@fontsource/roboto"
import Footer from "../sections/Footer"

const Layout = ({ children }) => {
  return (
    <main>
      {children}
      <Footer />
    </main>
  )
}

export default Layout
