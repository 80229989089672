import React from "react"
import { Grid, Box, Heading } from "@chakra-ui/react"
import Menu from "./components/Menu"
import HamburgerMenu from "./components/HamburgerMenu"

const Navbar = () => (
  <nav>
    <Grid
      // bg="tomato"
      py={5}
      templateColumns="repeat(2, 1fr)"
      gap={0}
      alignItems="flex-end"
    >
      <Box justifySelf="self-start">
        <Heading as="h1" size="md">
          Vali Sumurduc
        </Heading>
      </Box>
      <HamburgerMenu />
      <Box display={{ base: "none", md: "flex" }} justifySelf="self-end">
        <Menu />
      </Box>
    </Grid>
  </nav>
)

export default Navbar
