import React from "react"
import { Container, SimpleGrid, Box } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import AuthorAndRights from "../../components/AuthorAndRights"
import SocialLinks from "../../components/SocialLinks"

const Footer = () => (
  <footer>
    <div style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        alt="Footer background image"
        src="../../images/footer_bg.png"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <Container maxW="container.lg" pt={24} pb={20}>
          <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={5} py={4}>
            <Box textAlign={{ base: "center", md: "left" }}>
              <AuthorAndRights />
            </Box>
            <Box
              textAlign={{ base: "center", md: "right" }}
              alignSelf="flex-end"
            >
              <SocialLinks />
            </Box>
          </SimpleGrid>
        </Container>
      </div>
    </div>
  </footer>
)

export default Footer
