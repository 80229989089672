import React from "react"
import { Link, Icon } from "@chakra-ui/react"
import { links } from "./social"

const SocialLinks = () => (
  <>
    {links.map(({ id, name, link, icon: SocialIcon }, index, array) => (
      <Link
        key={id}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`follow me on ${name}`}
      >
        <Icon as={SocialIcon} alt={name} boxSize={7} ml={index === 0 ? 0 : 4} />
      </Link>
    ))}
  </>
)

export default SocialLinks
