import React from "react"
import { Link } from "react-scroll"
import { Text } from "@chakra-ui/react"
import { Container, ContainerResponsive } from "./Menu.styled"

const Menu = ({ onCloseDrawer }) => {
  const ContainerType = onCloseDrawer ? ContainerResponsive : Container

  return (
    <ContainerType>
      <Link
        to="section-about"
        spy={true}
        smooth={true}
        duration={1000}
        onClick={onCloseDrawer && onCloseDrawer}
        delay={200}
      >
        <Text fontWeight="bold">About</Text>
      </Link>
      <Link
        to="section-projects"
        spy={true}
        smooth={true}
        duration={1000}
        onClick={onCloseDrawer && onCloseDrawer}
        delay={200}
      >
        <Text fontWeight="bold">Projects</Text>
      </Link>
      <Link
        to="section-contact"
        spy={true}
        smooth={true}
        duration={1000}
        onClick={onCloseDrawer && onCloseDrawer}
        delay={200}
      >
        <Text fontWeight="bold">Contact</Text>
      </Link>
    </ContainerType>
  )
}

export default Menu
