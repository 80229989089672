import React from "react"
import { Heading, Text, Link } from "@chakra-ui/react"

const AuthorAndRights = () => (
  <>
    <Heading as="h2" size="lg">
      Vali Sumurduc
    </Heading>
    <Text>
      © All rights are reserved | {new Date().getFullYear()} | Made with{" "}
      <span aria-label="love" role="img">
        💖
      </span>{" "}
      by{" "}
      <Link
        href="https://valisum.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        ValiSum
      </Link>
    </Text>
  </>
)

export default AuthorAndRights
