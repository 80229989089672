import React, { useState, useRef } from "react"
import Recaptcha from "react-google-recaptcha"
import {
  Button,
  FormControl,
  Input,
  Textarea,
  Box,
  useToast,
} from "@chakra-ui/react"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [formValues, setFormValues] = useState({})
  const recaptchaRef = useRef()
  const toast = useToast()

  const onChangeInput = event =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value })

  const resetForm = form => {
    form.reset()
    recaptchaRef.current.reset()
  }

  const onSubmit = event => {
    event.preventDefault()
    const form = event.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...formValues,
      }),
    })
      .then(() => {
        toast({
          title: "Mail sent!",
          description: "I will contact you soon.",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
        resetForm(form)
      })
      .catch(() => {
        toast({
          title: "An error has occurred!",
          description:
            "If the problem persists you can contact me by other ways indicated at the footer.",
          status: "error",
          duration: 4000,
          isClosable: true,
        })
        resetForm(form)
      })
  }

  return (
    <form
      id="contactForm"
      name="contact-form"
      data-netlify-recaptcha="true"
      data-netlify="true"
      onSubmit={onSubmit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <FormControl id="name" pb={6} isRequired>
        <Input
          size="lg"
          name="name"
          placeholder="Full name *"
          type="text"
          onChange={onChangeInput}
        />
      </FormControl>
      <FormControl id="email" pb={6} isRequired>
        <Input
          size="lg"
          name="email"
          placeholder="Email *"
          type="email"
          onChange={onChangeInput}
        />
      </FormControl>
      <FormControl id="message" pb={6} isRequired>
        <Textarea
          size="lg"
          name="message"
          placeholder="Message *"
          resize="none"
          onChange={onChangeInput}
        />
      </FormControl>
      <Box pb={6}>
        <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
      </Box>
      <Button size="lg" variant="solid" type="submit">
        Submit
      </Button>
    </form>
  )
}

export default ContactForm
