import React from "react"
import { Link } from "react-scroll"
import {
  Container,
  Button,
  Box,
  Heading,
  Text,
  SimpleGrid,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const About = () => (
  <section id="section-about">
    <Container maxW="container.lg" pt={12} pb={10}>
      <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={5} pt={4}>
        <Box justifySelf="self-end">
          <StaticImage src="../../images/about.png" alt="About" />
        </Box>
        <Box justifySelf="self-start" alignSelf="center" textAlign="left">
          <Heading as="h2" size="xl">
            More about me
          </Heading>
          <Text fontSize="xl" py={4}>
            I am a Romanian currently living in Spain. I discovered programming
            a few years ago and it has become my passion. I love Javascript and
            all the libraries and frameworks that come from it, especially React
            and Node. The rest of my time is dedicated to my other passions such
            as Crossfit and riding my motorcycle on the road.
          </Text>
          <Button size="lg" colorScheme="primaryBg" variant="solid">
            <Link
              activeClass="active"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Hire me
            </Link>
          </Button>
        </Box>
      </SimpleGrid>
    </Container>
  </section>
)

export default About
