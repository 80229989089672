import React from "react"
import { SimpleGrid, Box, Heading, Text, Button } from "@chakra-ui/react"

const Project = ({ name, description, link }) => (
  <Box height="auto" p={2} boxShadow="base" borderRadius={4}>
    <SimpleGrid columns={1} height="100%">
      <Heading as="h4" size="md" py={4}>
        {name}
      </Heading>
      <Box>
        <p>{description}</p>
      </Box>
      <SimpleGrid columns={2} spacing={2} pt={4} alignItems="end">
        <Box textAlign="left" alignSelf="center">
          <Text fontSize="xs" fontWeight="bold">
            Javascript
          </Text>
        </Box>
        <Box textAlign="right">
          <Button
            as="a"
            colorScheme="primaryBg"
            variant="outline"
            target="_blank"
            href={link}
          >
            Show more
          </Button>
        </Box>
      </SimpleGrid>
    </SimpleGrid>
  </Box>
)

export default Project
