import React from "react"
import { Link } from "react-scroll"
import { Container, SimpleGrid, Box, Heading, Button } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import Navbar from "../../components/Navbar"

const Header = () => (
  <header>
    <div style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        alt="Header background image"
        src="../../images/header_bg.png"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <Container maxW="container.lg">
          <Navbar />
        </Container>
        <Container maxW="container.lg" pt={12} pb={10}>
          <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={5} alignItems="center">
            <Box justifySelf="self-start">
              <Heading as="h2" size="3xl">
                Hi there!
              </Heading>
              <Heading as="h3" size="xl" py={4}>
                I’m Vali and I’m a MERNStack and Mobile App developer!
              </Heading>
              <Button size="lg" variant="solid">
                <Link
                  activeClass="active"
                  to="section-contact"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  Hire me
                </Link>
              </Button>
            </Box>
            <Box justifySelf="self-end">
              <StaticImage src="../../images/intro.png" alt="Intro" />
            </Box>
          </SimpleGrid>
        </Container>
      </div>
    </div>
  </header>
)

export default Header
