import React, { useState, useEffect } from "react"
import { animateScroll } from "react-scroll"
import { IconButton } from "@chakra-ui/react"
import { IoChevronUpOutline } from "react-icons/io5"

const ScrollButton = () => {
  const [hideScrollUpButton, setHideScrollUpButton] = useState(true)

  const onScroll = () => {
    let currentScrollPos = window.scrollY
    if (currentScrollPos > 0) {
      setHideScrollUpButton(false)
    } else {
      setHideScrollUpButton(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <IconButton
      variant="solid"
      colorScheme="solid"
      position="fixed"
      zIndex="1"
      right="16px"
      bottom="16px"
      aria-label="Go up"
      fontSize="30px"
      size="lg"
      isRound
      hidden={hideScrollUpButton}
      icon={<IoChevronUpOutline />}
      onClick={() => animateScroll.scrollToTop()}
    />
  )
}

export default ScrollButton
