import {
  FaTelegram,
  FaGithub,
  FaStackOverflow,
  FaTwitter,
} from "react-icons/fa"

export const links = [
  {
    id: 0,
    name: "Telegram",
    link: "https://t.me/iamvalisum",
    icon: FaTelegram,
  },
  {
    id: 1,
    name: "Github",
    link: "https://github.com/valisum",
    icon: FaGithub,
  },
  {
    id: 2,
    name: "StackOverflow",
    link: "https://stackoverflow.com/users/5781604/valisum",
    icon: FaStackOverflow,
  },
  {
    id: 3,
    name: "Twitter",
    link: "https://twitter.com/iamvalisum",
    icon: FaTwitter,
  },
]
