import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  /* margin: 0;
  padding: 0; */

  a {
    cursor: pointer;
    :not(:last-child) {
      margin-right: 10px;
    }
    :hover {
      text-decoration: underline;
      color: red;
    }
  }
`

export const ContainerResponsive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  a {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
    font-size: 24px;
    padding: 20px 0;
  }
`
