import React from "react"
import Layout from "../layout"
import Seo from "../components/Seo"
import ScrollButton from "../components/ScrollButton"

// Sections
import Header from "../sections/Header"
import Projects from "../sections/Projects"
import About from "../sections/About"
import Contact from "../sections/Contact"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Home"
        description="This is the homepage for a gatsby website"
      />
      <ScrollButton />
      <Header />
      <Projects />
      <About />
      <Contact />

      {/* <aside>
        <h2>About section</h2>
        <p>
          Donec eu libero sit amet quam egestas semper. Aenean ultricies mi
          vitae est. Mauris placerat eleifend leo.
        </p>
      </aside> */}
    </Layout>
  )
}

export default IndexPage
