import React, { useState, useEffect } from "react"
import { Container, SimpleGrid, Box, Heading, Spinner } from "@chakra-ui/react"
import Project from "../../components/Project"

const Projects = () => {
  const [projects, setProjects] = useState()

  useEffect(() => {
    fetch("https://api.github.com/users/ValiSum/repos")
      .then(response => response.json())
      .then(data => setProjects(data))
  }, [])

  return (
    <section id="section-projects">
      <Container maxW="container.lg" pt={12} pb={10}>
        <Heading as="h2" size="xl">
          Projects
        </Heading>
        {projects ? (
          <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={5} pt={4}>
            {projects &&
              projects.map(project => (
                <Project
                  key={project.id}
                  name={project.name}
                  description={project.description}
                  link={project.html_url}
                />
              ))}
          </SimpleGrid>
        ) : (
          <Box spacing={5} pt={4} textAlign="center">
            {/* ƪ(ړײ)ƪ
          <br />
          Ideas factory in progress... */}
            <Spinner size="lg" />
          </Box>
        )}
      </Container>
    </section>
  )
}

export default Projects
