import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = () => {
  const {
    site: {
      siteMetadata: { title, description, keywords, siteUrl, ogImage, lang },
    },
    favicon: { publicURL },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
          ogImage
          lang
        }
      }
      favicon: file(name: { eq: "favicon" }) {
        publicURL
      }
    }
  `)

  return (
    <Helmet htmlAttributes={{ lang: lang }} titleTemplate={title}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}/${ogImage}`} />
      <meta name="image:alt" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />
      <link rel="canonical" href={siteUrl} />
      <link rel="shortcut icon" href={publicURL} />

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}/${ogImage}`} />
      <meta property="og:image:alt" content={description}></meta>

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={siteUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}/${ogImage}`} />
      <meta name="twitter:image:alt" content={description}></meta>
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
}

export default Seo
